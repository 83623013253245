import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'



const RatingStar = () => (


  <>
  <div class="star">
    <FontAwesomeIcon icon={faStar}/>
  </div>
  </>
)

export default RatingStar;