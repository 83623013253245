import * as React from "react";
import RatingStar from "../components/RatingStar";
import SwiperCore, { Autoplay } from 'swiper';
import {Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';





const TestimonialsSection = ({data}) => {

const drawStar = ( count ) => {

 const n = count;

 return [...Array(n)].map((e, i) => <RatingStar key={i}/>)
}

SwiperCore.use([Autoplay])
    return(
        <div className="testimonials col-sm-12" id="testimonials">
            <div className="container">
                <h1 className="section-header testimonials-section-header text-center border-bottom-1">Das sagen unsere Kunden</h1>
                <hr className="section-title-divider"/>
                <div className="testimonials-container">
                <Swiper
                        autoplay={{ 
                            delay: 7000,
                            disableOnInteraction: false,
                            }}
                        spaceBetween={1}
                        slidesPerView="auto"
                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                            width: 640,
                            slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            768: {
                            width: 768,
                            slidesPerView: 2,
                            },  
                        }}
                >
                {
                        data.testimonials.nodes.map(node => (
                    <SwiperSlide>
                    <div className="testimonial col-md-12 col-lg-4 col-xs-12">
                        <div className="card testimonial-card p-3 text-left px-4">
                     
                            <div className="user-content">
                                <q>{node.text.text}</q>
                                <h2 className="mb-2">{node.kundenName}</h2>
                                <h5 className="mb-2">{node.kundenFirma}</h5>
                            </div>
                            <div className="ratings">
                            {drawStar(node.rating)}
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>
                    ))
                    }
                   
                    </Swiper>
                </div>
            </div>
        </div>
        )
    }

export default TestimonialsSection;