import React, {useState} from "react";


const ContactSection = () => {

    const [formState, setFormState] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleChange = e => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({"form-name": "contact", ...formState})
        })
            .then(() => alert("Ihre Anfrage wurde erfolgreich gesendet!"))
            .catch(error => alert(error));

        e.preventDefault();
    }

    return (
        <section id="contact" className="contact">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Wir beraten Sie gerne.</h2>
                        <hr/>
                    </div>
                </div>
                <form onSubmit={handleSubmit} name="contact" method="post" data-netlify="true"
                      data-netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="contact"/>
                    <div className="row">
                        <div className="col-md-7 form-left">
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="name" className="form-label">Nachname,
                                        Vorname*</label>
                                </div>
                                <div className="col-8">
                                    <input type="text"
                                           className="form-control"
                                           id="name"
                                           name="name"
                                           aria-describedby="nameHelp"
                                           onChange={handleChange}
                                           value={formState.name}
                                           required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="phone"
                                           className="form-label">Telefonnummer</label>
                                </div>
                                <div className="col-8">
                                    <input type="text"
                                           className="form-control"
                                           id="phone"
                                           name="phone"
                                           aria-describedby="phoneHelp"
                                           onChange={handleChange}
                                           value={formState.phone}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="email" className="form-label">E-Mail Adresse*</label>
                                </div>
                                <div className="col-8">
                                    <input type="email"
                                           className="form-control"
                                           id="email"
                                           name="email"
                                           aria-describedby="emailHelp"
                                           onChange={handleChange}
                                           value={formState.email}
                                           required
                                    />
                                </div>
                            </div>


                            <p className='terms'>Die von Ihnen eingetragenen Daten werden vertraulich behandelt und nicht an Dritte
                                weitergegeben. Sollte keine Geschäftsbeziehung zwischen Ihnen und der Nova Bau GmbH
                                zustande kommen, dann werden Ihre Daten nach 6 Monaten von uns gelöscht.
                                Ich bin damit einverstanden, dass meine Angaben und Daten zur Bearbeitung meiner Anfrage
                                elektronisch gespeichert werden. Datenschutzerklärung ansehen.</p>
                            <div className='d-flex'>
                                <label className="form-check-label terms-label" htmlFor="flexCheckDefault">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <span className="styled-checkbox"/>Ich habe die Datenschutzerklärung gelesen,
                                    verstanden und stimme dieser zu.*
                                </label>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="message" className="form-label">Ihr Anliegen*</label>
                            <textarea required
                                      id="message"
                                      className="form-control"
                                      name="message"
                                      rows="5"
                                      cols="50"
                                      onChange={handleChange}
                                      value={formState.message}
                            />
                            <button type="submit" className="btn btn-primary">Absenden</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
)
}

export default ContactSection;
