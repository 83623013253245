import  React from "react";
import {BLOCKS, MARKS} from "@contentful/rich-text-types";
import { renderRichText } from 'gatsby-source-contentful/rich-text'


const ServiceSection = ({data} ) => {

const dynamicClass = (id ) => {
    if (id === 1) {
       return "desc".toString();
    } else if (id === 3 || id === 4) {
        return "desc-four-item".toString();
    } else {
    return "desc-tree-item".toString();
    }
}

    const Bold = ({ children }) => <span className="bold">{children}</span>
    const Text = ({ children }) => <p className="align-center">{children}</p>

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [BLOCKS.EMBEDDED_ASSET]: node => {
                return (
                    <>
                        <h2>Embedded Asset</h2>
                        <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
                    </>
                )
            },
        },
    }

    return (
        <div className="services" id="services">
            <div className="container">
                <h1 className="section-header text-center border-bottom-1">Unsere Leistungen</h1>
                <hr className="section-title-divider"/>

                <div className="row services-container">
                    {
                        data.services.nodes.map(node => (

                            <div className="col-lg-3 col-sm-3 col-md-6">
                                <div key={node.contentfulid} className="service-box">
                                    <p>{node.title}</p>
                                    <img
                                        alt={node.iconAlt}
                                        src={node.icon.file.url}
                                    />
                                    <div className={dynamicClass(node.contentfulid)}>
                                             {renderRichText(node.description, options)}
                                    </div>
                                </div>
                            </div>

                        ))
                    }
                </div>
            </div>
        </div>

    )}
export default ServiceSection;
