import React from "react";



const HeroSection = ({data}) => (



   <>
    <section className="hero">
       <div className="hero-inner">
           <div className="row">
               <div className="col-md-4">
                   <div className="blocks">
                       <i className="i-first"/>
                       <i className="i-second"/>
                       <i className="i-third"/>
                   </div>
               </div>
               {
                   data.home.nodes.map(node => (
               <div className="col-md-8">
            
               <h1>{node.heroText}</h1>
                   <p>{node.heroSubtitle}</p>    
               </div>
               ))
            }
           </div>
           
           
       </div>
    </section>
   </>
)


export default HeroSection;