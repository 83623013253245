import * as React from "react";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MDXProvider } from "@mdx-js/react"

const AboutSection = ({data}) => {
   return (
        <section id="about" className="about">
            <div className="container">
                <h1 className="section-header text-center border-bottom-1">Über uns</h1>
                <hr className="section-title-divider"/>
                <div className="row about-container">
                    {data.home.nodes.map(node => (
                    <div className="col-md-6">
                        <div className="about-cards">

                            <div className="card border-0 bg-transparent">


                                <img className="card-img-top" alt="Foto von Alexandru Novacescu"
                                     src={node.alexPhoto.file.url}
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{node.alexName}</h5>
                                    <p> {node.alexGrade}</p>
                                    <p className="card-text">{node.alexFunctionality.alexFunctionality}</p>
                                </div>
                            </div>
                            <div className="card border-0 bg-transparent">
                                <img className="card-img-top" alt="Foto von Diana Novacescu"
                                     src={node.dianaPhoto.file.url}/>
                                <div className="card-body">
                                    <h5 className="card-title">{node.dianaName}</h5>
                                    <p>{node.dianaGrade}</p>
                                    <p className="card-text">{node.dianaFunctionality.dianaFunctionality}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
                    }
                    <MDXProvider>
                    <div className="col-md-6">
                    {data.home.nodes.map(node => (
                        <div className="about-text">
                        <p>
                        <MDXRenderer>{node.aboutUsDescription.childMdx.body}</MDXRenderer>
                        </p>

                        </div>
                    ))
                    }
                    </div>
                    </MDXProvider>
                </div>
            </div>

        </section>


   )
}

export default AboutSection;