import * as React from "react"
import HeroSection from "../sections/hero";
import AboutSection from "../sections/about";
import TestimonialsSection from "../sections/testimonials";
import ServiceSection from "../sections/service";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql} from "gatsby";
import ContactSection from "../sections/contact";

export const data = graphql`
query MyQuery {
  home: allContentfulHomePage {
    nodes {
      title
      heroText
      heroSubtitle
      alexPhoto {
        file {
          url
        }
      }
      dianaPhoto {
        file {
          url
        }
      }
      alexName
      alexGrade
      dianaName
      dianaGrade
      dianaFunctionality {
        dianaFunctionality
      }
      alexFunctionality {
        alexFunctionality
      }
      aboutUsDescription {
        childMdx {
          body
        }
      }
      seo {
        description {
          description
        }
      }
      bookingCalenderUrl
    }
  }
  services: allContentfulServices(sort: {fields: contentfulid, order: ASC}) {
    nodes {
      title
      contentfulid
      description {
        raw
      }
      iconAlt
      icon {
        file {
          url
        }
      }
    }
  }
  testimonials: allContentfulTestimonials {
    nodes {
      kundenName
      kundenFirma
      rating
      text {
        text
      }
    }
  }
  impressum: allContentfulImpressum {
    nodes {
       impressumDescription {
        raw
      }
    }
  }
}

`;



const IndexPage = ( {data}) => (
    <>
         <Layout>
             {
                 data.home.nodes.map( node => (
             <Seo
                title={node.title}
                description={node.seo.description.description}
             />
                 ))
             }
                <HeroSection data={data}/>
                <AboutSection data={data}/>
                <ServiceSection data={data}/>
                <TestimonialsSection data={data} />
                <ContactSection />
         </Layout>
    </>
)

export default IndexPage